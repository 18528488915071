import React from "react";
import Navbar from "/src/containers/navbar";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import config from "/src/config.js";
import SEO from "/src/components/seo";
import Faqs from "../../components/faqs";

const FaqsPage = () => {
  return (
    <>
      <SEO
        title={`${config.CLIENT_NAME} - Faqs`}
        description={config.CLIENT_DESCRIPTION}
        image={encodeURI(config.LOGO_DARK) || ""}
      />
      <BacThemeProvider>
        <Navbar />
        <Faqs />
      </BacThemeProvider>
    </>
  );
};

export default FaqsPage;
